var Aneox;
(function(Aneox) {
    (function(Controllers) {
        'use strict';

        var Distributeurs = (function() {

            var self;
            var windowHeight;
            var hasPlayedThroughAnimation = {};
            var mastElement;
            var sections;
            var continentIds
            var dataRendered = false;


            function Distributeurs(animations, locale, router, drupaldata) {

                self = this;
                self.timeline = animations.timeline;
                self.animations = animations;
                self.drupalData = drupaldata;
                self.locale = locale;
                
                mastElement = "#distributeurs";
                sections = ['mast','distributeurs'];
               
            }

            Distributeurs.prototype.enter = function() {
                
                //hasPlayedThroughAnimation = {};
                var menuLeft = $('#nav-menu').position().left;
                if(menuLeft < 0) {
                  self.timeline.to('#nav-menu', 1, {left: 0, opacity: 1});
                } // vérif position du menu avant toute apparition
 
                self.setData();
                self.timeline.to('#languages-nav', 0.25, {opacity: 1});
                self.timeline.set('.credit', {opacity: 0});
                self.timeline.set(mastElement, { display: 'block', opacity: 0 });
                self.timeline.call(self.handleWindowResize.bind(self, $(window).height()));
                self.timeline.call(function() { 
                    $('#right-content').scrollTop(0); 
                    self.animations.setSvgHome('home-svg-8-mask');
                });
                self.timeline.to(mastElement, .5, { opacity: 1, display: 'block' });
                self.timeline.call(function(){
                    TweenMax.to('.background-mast', 3, {opacity: 1, scale: 1, ease: Circ.easeOut});
                    self.animations.animSvgHome('home-svg-8-mask','distributeurs');
                });
                
                if (Aneox.Data.Config.runtime.supportsScrollEvents) {

                }
             };
 
             Distributeurs.prototype.leave = function(callback) {
                 self.timeline.to(mastElement, .5, { display: 'none', opacity: 0 });
                 self.timeline.set('.background-mast', {opacity: 0, scale: 1.05});
                 self.timeline.set(mastElement+'-title-1, '+mastElement+'-title-2', {opacity:0})
                 if (callback) self.timeline.call(callback);
                
             };

            Distributeurs.prototype.handleWindowResize = function(height) {
                windowHeight = height;
            };

            Distributeurs.prototype.handleScroll = function(event) {
                if (!Aneox.Data.Config.runtime.supportsScrollEvents) return;
                
                var offsetTop = $(mastElement +'-distributeurs').offset().top;
                    
                    if (offsetTop < .75 * windowHeight){
                      
                          TweenMax.to('.credit', 0.25, {display: 'block', opacity: 1});    
                        
                    }else{
                        TweenMax.to('.credit', 0.25, {display: 'none', opacity: 0});
                    }
                
            };

            Distributeurs.prototype.mouseEnter = function(area) {
                if (Aneox.Data.Config.runtime.mobile) return;
            };

            Distributeurs.prototype.mouseLeave = function(area) {
                if (Aneox.Data.Config.runtime.mobile) return;
            };
            
            Distributeurs.prototype.setData = function(locale){
                
                if(dataRendered === true){
                    return;
                } 
                
                var dataContinents = self.drupalData.dataContinentsPays;
                var dataDistributors = self.drupalData.dataDistributors;
                
                var tempContinents = _.filter(dataContinents, {parent_tid: 0});
                var countries = _.filter(dataContinents, function(o){
                    if(o.parent_tid != 0) return o;
                });
                continentIds = tempContinents.map(function(continent) {
                    return continent.tid;
                });
                
                
                tempContinents.forEach(function(continent){

                    var name = _.isArray(continent.name) ? _.find(continent.name, { locale: 'fr' }).value : continent.name;
                    delete continent.name;
                    

                    continent.countries = [];
                    continent.name = [];
                    continent.name.push({locale: "fr", value: name}, {locale: "en", value: continent.localeen}, {locale: "es", value: continent.localees}, {locale: "it", value: continent.localeit});
                    
                    countries.sort(function(c1, c2) {
                        
                          var name1 = _.isArray(c1.name) ? _.find(c1.name, { locale: self.locale.getLocale() }).value : c1.name;
                          var name2 = _.isArray(c2.name) ? _.find(c2.name, { locale: self.locale.getLocale() }).value : c2.name;
                          if (name1 > name2) {
                            return 1;
                          } else if (name1 < name2) {
                            return -1;
                          } else {
                            return 0;
                          }
                    });
                    
                    countries.forEach(function(country){

                        if(country.parent_tid === continent.tid){

                            var countryName = _.isArray(country.name) ?
                              _.find(country.name, { locale: 'fr' }).value : country.name;
                            delete country.name

                            country.distributors = [];
                            country.name = [];

                            country.name.push({locale: "fr", value: countryName}, {locale: "en", value: country.localeen}, {locale: "es", value: country.localees}, {locale: "it", value: country.localeit});
                            continent.countries.push(country);
                            
                            dataDistributors.forEach(function(distributor){

                            if(distributor.countries == country.tid){
                                country.distributors.push(distributor);
                              }
                            });

                            if(country.distributors.length == 0){
                              _.pull(continent.countries, country);
                            }   
                        }
                    });
                });

                  self.dataContinentsDistri = tempContinents.filter(function(el){
                      if(el.countries.length > 0) return el;
                  });
                
                renderContinentsList();
                dataRendered = true;
                
            };
            
            
            function appendDistributorsList(continent){
                var name = _.isArray(continent.name) ?
                _.find(continent.name, { locale: self.locale.getLocale() }).value : continent.name;
               
                var list = '#distributeurs-lists .list-right';
                
                self.timeline.to(list, .5, { opacity: 0});
                self.timeline.call(function(){
                    $("#distributeurs-lists .list-right").html(continent.countries.reduce(function(accumulator, country){
                    return accumulator + '<h3>'+(_.isArray(country.name) ? _.find(country.name, { locale: self.locale.getLocale() }).value : country.name)+'</h3>'+
                        generateDistributorsList(country);
                },''));
                });
                self.timeline.to(list, .5, { opacity: 1});
                
            }
            
            function generateDistributorsList(country){
                if (!_.isArray(country.distributors)) {
                    country.distributors = [country.distributors];
                }  
                
                return country.distributors.reduce(function(accumulator, distributor){
                    
                    var region = distributor.region != null ? '<h4>'+distributor.region+'</h4>': '';
                    var name = "<h5>"+distributor.name+"</h5>";
                    
                    return accumulator + region + name +'<p>'+
                        _.reduce(distributor, function(accumulator, value, key){
                        
                        switch(key){
                            case "status":
                                 return value != null ? accumulator + value +'<br>' : accumulator;      
                                break;
                            case "tel":
                                 return value != null ? accumulator + 'Tel. <a href="tel:'+value+'">'+value+'</a><br>' : accumulator;      
                                break;
                            case "email":
                                 return value != null ? accumulator + '<a href="mailto:'+value+'">'+value+'</a><br>' : accumulator;      
                                break;
                            case "site":
                                 return value != null ? accumulator + '<a target="_blank" href="http://'+value+'">'+value+'</a>' : accumulator;      
                                break;
                            default:
                                return accumulator;
                        }
                    }, '')+'</p><br>';
                }, '');
            }
            
            
            function renderContinentsList(){
                
                $("#distributeurs-lists .list-left").empty();
                
                var data = self.dataContinentsDistri;
                var fragment = $(document.createDocumentFragment());
               
                var i = 0;
                
                data.forEach(function(continent){
                    var name = _.isArray(continent.name) ?
                    _.find(continent.name, { locale: self.locale.getLocale() }).value : continent.name;
                    
                    
                    var li = $('<li>'+name+'</li>');
                    
                    if(i == 0){
                        li.addClass('active');
                        appendDistributorsList(continent);
                    } 
                    
                    li.on('click', function(e){
                        appendDistributorsList(continent);
                        $("#distributeurs-lists .list-left li").removeClass('active');
                        $(e.target).addClass('active');
                    });
                    
                    fragment.append(li);
                    i++;
                });
                
                $("#distributeurs-lists .list-left").html(fragment);

                
            }
            

            return Distributeurs;
        })();

        Controllers.Distributeurs = Distributeurs;
    })(Aneox.Controllers || (Aneox.Controllers = {}));
})(Aneox || (Aneox = {}));