var Aneox;
(function (Aneox) {
    'use strict';

    var NavMenu = (function () {

        var images;
        var currentIndex;
        var self;

        var mobileMenuElement;
        var MobileButtonElement;

        function NavMenu(anims, locale, router) {
            this.animations = anims;
            
            self = this;
            
            mobileMenuElement = "#nav-mobile";
            MobileButtonElement = "#mobile-button";
            
            $(MobileButtonElement).on('click', function(e){
              if( $(mobileMenuElement).css('display') == 'none' ) {
                self.openMobileMenu();
              } else {
                self.closeMobileMenu();
              }
            });
            
        }
        
        NavMenu.prototype.openMobileMenu = function() {
            self.animations.timeline.call(function(){
               $(MobileButtonElement).addClass('close'); 
            });
            self.animations.timeline.to(mobileMenuElement, .5, { display: 'block', opacity: 1 });
            
        };

        NavMenu.prototype.closeMobileMenu = function() {
            self.animations.timeline.call(function(){
                $(MobileButtonElement).removeClass('close');
            });
            self.animations.timeline.to(mobileMenuElement, .5, {opacity: 0, display: 'none' });
            
        };
        
        return NavMenu;
    })();

    Aneox.NavMenu = NavMenu;
})(Aneox || (Aneox = {}));