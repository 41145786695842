var Aneox;
(function(Aneox) {
    'use strict';

    var App = (function() {

        var isTouchDevice;

        var currentLocale;
        var currentNode;
        var currentController;

        var firstLoad = true;

        var listenForScroll = true;
        var manualScroll = false;

        var isWidescreen;

        var animations;

        var footer;

        var rightContent;

        var navigationCounter = 0;

        var self;

        function App(fonts, locale, router, analytics, drupalData, anims, slideshow, navmenu,
            homeController, actualitesController, distributeursController, matieresController,
            gammeController, savoirfaireController, tonnellerieController,
            contactController, legalController) {
            
            
            this.fonts = fonts;
            this.locale = locale;
            this.router = router;
            this.analytics = analytics;
            this.drupalData = drupalData;
            this.navmenu = navmenu;
            this.controllers = {
                home: homeController,
                actualites: actualitesController,
                distributeurs: distributeursController,
                matieres: matieresController,
                savoirfaire: savoirfaireController,
                gamme: gammeController,
                tonnellerie: tonnellerieController,
                contact: contactController,
                legal: legalController
            };
            this.animations = anims;
            this.slideshow = slideshow;
            self = this;
            
            lazySizesConfig.preloadAfterLoad = true;
            
            
            rightContent = $('#right-content');
            
            var footer = $('footer');
            
            self.byPassBot = false;
            
            var md = new MobileDetect(window.navigator.userAgent);
            Aneox.Data.Config.runtime = {
                isTouchDevice: 'ontouchstart' in document.documentElement,
                mobile: md.mobile(),
                tablet: md.tablet(),
                phone: md.phone(),
                supportsScrollEvents: !md.mobile() || (md.is('iOS') && md.is('Safari') && md.version('Safari') > 8)
            };
            
            self.router.bind('pageChange', function(path, node, nodeLocale) {
                
                // animation on page title
                
                self.navmenu.closeMobileMenu();
                
                if(node.id != "legal"){
                    navigationCounter++;
                }

                if(navigationCounter > 1 && !Cookies.get('acceptsCookies')){
                    setAcceptCookies();
                    self.analytics.startAnalytics();
                    TweenMax.set('#cookies',{ opacity: 0, display: 'none' });
                }
                
                //if (!Cookies.get('acceptsCookies') && !$('#cookies').is(':visible')) {
                if (!Cookies.get('acceptsCookies')) {
                    
                    TweenMax.to('#cookies', 0.5, { opacity: 1, display: 'block' });
                }else{
                    
                    if(Cookies.get('acceptsCookies') === 'true' && !self.analytics.analyticsEnabled){

                        self.analytics.startAnalytics();
                    }
                    
                    TweenMax.set('#cookies',{ opacity: 0, display: 'none' });
                }

                /* if (!Cookies.get('acceptsCookies') && !$('#cookies').is(':visible')) {
                    TweenMax.to('#cookies', 0.5, { opacity: 1, display: 'block' });
                    
                }else{
                    TweenMax.set('#cookies',{ opacity: 0, display: 'none' });
                } */
                
                self.locale.setLocale(nodeLocale.locale);

                if (_.get(currentNode, 'id') === node.id) return;
                
                var rootNodeId, controllerId;
                
                // Enter/Leave animations
                if (_.get(currentNode, 'id') !== node.id) {
                    switch (_.get(currentNode, 'type')) {
                        case 'basic-page':
                            self.controllers[currentNode.id].leave();
                            /*animations.timeline.to(footer, .5, { opacity: 0, display: 'none' }, '-=.5');
                            animations.timeline.call(function() {
                                $('#right-content').scrollTop(0);
                            });*/
                            break;
                    }
                    
                    switch (node.type) {
                        
                        default:
                            
                            //check if splash screen on this site
                            if(Aneox.Data.Config.splash === 'true'){
                                if (Cookies.get('splashscreen') == 'true' || self.byPassBot === true){
                                    self.controllers[node.id].enter();
                                }else{
                                    $(document).bind("acceptSplash", function(event){
                                        currentController.enter();
                                    });
                                } 
                            }else{
                                self.controllers[node.id].enter();
                            }
                            
                            rootNodeId = node.id;
                            controllerId = node.id;
                            break;
                    }
                    firstLoad = false;
                }

                // Update active menu item
                $('.nav-link').removeClass('active');
                $('.nav-link[router-href="' + rootNodeId + '"]').addClass('active');

                currentNode = node;
                currentController = self.controllers[controllerId];
            });

            self.locale.bind('localeChange', function(locale) {
               
                if (currentLocale === locale) return;
                
                $('#languages-nav li, #nav-mobile-lang li').removeClass('active');
                $('#languages-nav li a[router-locale="' + locale + '"], #nav-mobile-lang li a[router-locale="' + locale + '"]').parent().addClass('active');
                
                
                // if(!_.isEmpty(currentNode)){
                //      switch(currentNode.type){
                //          case 'producer-page':
                //             self.controllers.producers.swap(currentNode.id);
                //             break;
                //     }
                // } 

                setTimeout(handleWindowResize);
                self.router.reparseAnchors();
            });

            // initiate the site
            function initiate(event) {

                TweenMax.to('#loading', 0.5, { opacity: 0, display: 'none' });
                    
                self.router.init();
                

                var throttledWindowResizeHandler = _.throttle(handleWindowResize, 500, { leading: false, trailing: true });
                $(window).resize(throttledWindowResizeHandler);
                Visibility.change(throttledWindowResizeHandler);
                self.fonts.bind('fontsActive', handleWindowResize);
                handleWindowResize();

                // Scroll handling
                rightContent.on('scroll', _.throttle(handleScroll, 500, { leading: false, trailing: true }));

                var nav = $('#nav-menu');

                function handleScroll(event) {
                    if (!listenForScroll) {
                        return;
                    }
                    if (currentController && currentController.handleScroll) {
                        currentController.handleScroll.call(currentController, event);
                    }

                    var scrollTop = $(this).scrollTop();

                }

            }
            
            
            // Window resize handling
            var handleWindowResize = function() {
                var screenWidth = _.get(window, 'screen.width', 0);
                var screenHeight = _.get(window, 'screen.height', 0);
                var maxScreenSize = Math.max(screenWidth, screenHeight);
                var screenOrientation = _.get(window, 'orientation', 0);
                var isPortrait = screenOrientation === 0 || screenOrientation === 180 || screenOrientation === -180;

                var windowHeight = $(window).height();
                var windowWidth = $(window).width();

                self.windowHeight = windowHeight;

                 _.forEach($('[screen-height]'), function(element) {
                     var heightElement = $(element);
                     var topMargin = _.parseInt(heightElement.css('margin-top'));
                     var bottomMargin = _.parseInt(heightElement.css('margin-top'));
                     var height = windowHeight - topMargin - bottomMargin;

                     heightElement.innerHeight(height);
                 });

                _.forEach($('[height-set]'), function (element) {
                  var sectionHeightElement = $(element);
                  var height = parseInt(sectionHeightElement.attr('height-set') || '0');

                  sectionHeightElement.outerHeight(height);
                });
                
                _.forEach(self.controllers, function(controller) {
                    controller.handleWindowResize($(window).height());
                })

            };
            
            var botAgent = "(bot|google|aolbuild|baidu|bing|msn|duckduckgo|teoma|slurp|yandex|Prerender)";
           
            var re = new RegExp(botAgent, 'i');
            var bot = re.test(navigator.userAgent);
            
            
            if(Aneox.Data.Config.splash === 'true'){
                if (Cookies.get('splashscreen') !== 'true' && bot === false ) {
                     var now = new Date();
                     now.setFullYear(now.getFullYear() - 18);
                     var nowText = now.toISOString().substring(0, 10).split('-');
                     $('#splashscreen-mast .date').text(nowText[2] + '/' + nowText[1] + '/' + nowText[0]);
                     TweenMax.set('#splashscreen', { display: 'block', opacity: 1 });
                     //TweenMax.fromTo('#splashscreen-mast', 0.5, { display: 'block', opacity: 0 }, { opacity: 1 });
                 }else{
                     self.byPassBot = true
                 }    
            }
            
            if(Aneox.Data.Config.cms === "true"){
                $(document).on("dataLoaded", initiate)
                self.drupalData.init();
            }else{
                initiate();
            }
            
        };
  
        
        var setAcceptCookies = function(){
            Cookies.set('acceptsCookies', 'true' , { expires: 395 });
        }
        
        var setDeniedCookies = function(){
            Cookies.set('acceptsCookies', 'false', { expires: 7 });
        }
        
        App.prototype.acceptCookies = function() {           
            setAcceptCookies();
            self.analytics.startAnalytics();
            TweenMax.to('#cookies', 0.5, { opacity: 0, display: 'none' });
        };
        
        App.prototype.deniedCookies = function(){
            setDeniedCookies();
            TweenMax.to('#cookies', 0.5, { opacity: 0, display: 'none' });
        }
        
        App.prototype.acceptSplashAge = function() {
            Cookies.set('acceptsSplashAge', 'true', { expires: 395 });
            $('#splash').addClass('accepts-spash-age');
            //TweenMax.to('#splashscreen', 0.5, { opacity: 0, display: 'none' });
           
        };

        App.prototype.openMenu = function() {
            self.animations.timeline.to('#nav-menu', 1, {left: 0});
        };

        App.prototype.closeMenu = function() {
            //TweenMax.to('#nav-menu', .5, { opacity: 0, display: 'none' });
        };

        App.prototype.openLegal = function() {
            $('#nav-menu').addClass('menu');
            TweenMax.to('#legal, footer', .5, { opacity: 1, display: 'block' });
        };

        App.prototype.closeLegal = function() {
            TweenMax.to('#legal, footer', .5, { opacity: 0, display: 'none' });
        };

        App.prototype.scrollDown = function() {
            animatedScrollTo(
                document.getElementById('right-content'),
                self.windowHeight,
                500
            );
        };

        App.prototype.setScrollRouter = function(nodeId){
            if(currentNode.id !== nodeId){
                var nodePath = self.router.getNodePath(nodeId, currentLocale);
                self.router.setPath(nodePath);
            }
            manualScroll = false;
        }
        

        return App;
    })();

    Aneox.App = App;
})(Aneox || (Aneox = {}));