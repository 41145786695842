var Aneox;
(function(Aneox) {
    (function(Controllers) {
        'use strict';

        var Tonnellerie = (function() {

            var self;
            var windowHeight;
            var hasPlayedThroughAnimation = {};
            var mastElement;
            var sections;
            var sID = 0;
            
            function Tonnellerie(animations, locale, router) {

                self = this;
                self.timeline = animations.timeline;
                self.locale = locale;
                self.router = router;
                self.animations = animations;
                
                mastElement = "#tonnellerie";
                //sections = ['mast','thibaut-montgillard','nicolas-boillot', 'antoine-delage'];
                sections = ['mast','thibaut-montgillard','nicolas-boillot', 'merranderie', 'perspective'];
                
            }

            Tonnellerie.prototype.enter = function() {
                
                //hasPlayedThroughAnimation = {};
                var menuLeft = $('#nav-menu').position().left;
                if(menuLeft < 0) {
                  self.timeline.to('#nav-menu', 1, {left: 0, opacity: 1});
                } // vérif position du menu avant toute apparition
                self.timeline.to('#languages-nav', 0.25, {opacity: 1});
                self.timeline.set('.credit', {opacity: 0, display: 'none'});
                self.timeline.set(mastElement, { display: 'block', opacity: 0 });
                self.timeline.call(self.handleWindowResize.bind(self, $(window).height()));
                self.timeline.call(function() { 
                    $('#right-content').scrollTop(0);
                    self.animations.setSvgHome('home-svg-2-mask');
                    
                });
                self.timeline.to(mastElement, .5, { opacity: 1, display: 'block' });
                self.timeline.call(function(){
                    TweenMax.to('.background-mast', 3, {opacity: 1, scale: 1, ease: Circ.easeOut});
                    self.animations.setPagerNav(mastElement, sections);
                    self.timeline.to("#pager-nav", .5, {display: 'block', opacity: 1});
                    self.animations.animSvgHome('home-svg-2-mask','tonnellerie'); 
                });
                
                
                
                if (!Aneox.Data.Config.runtime.supportsScrollEvents) {

                    TweenMax.set('#tonnellerie-intro', {opacity: 1, y: -20});
                    sID = 0;
                    sections.forEach(function(section){
                        sID++;
                        self.animations.noScrollSvgInt(sID, section, "tonnellerie");
                        
                        var parentSection = document.getElementById('tonnellerie-'+section);
                          var parentSectionBg = $('#tonnellerie-'+section).find('.section-bg');
                          self.timeline.set(parentSectionBg, {opacity: 1, scale: 1});
                          
                          var SectionTxt = $('#tonnellerie-'+section).find('.content-bloc-1-content');
                          self.timeline.set(SectionTxt, {opacity: 1, y: -20});
                    });
                    
                    self.timeline.call(self.handleWindowResize.bind(self, $(window).height()));
                }
             };
 
             Tonnellerie.prototype.leave = function(callback) {
                self.timeline.to(mastElement, .5, { display: 'none', opacity: 0 });
                self.timeline.to("#pager-nav", .5,{display: 'none', opacity: 0}, "-=.5");  
                self.timeline.to(".credit", .5,{opacity: 0, display: 'none'}, "-=.5");
                self.timeline.set('.background-mast', {opacity: 0, scale: 1.05});
                self.timeline.set(mastElement+'-title-1, '+mastElement+'-title-2', {opacity:0});

                if (callback) self.timeline.call(callback);
             };

            Tonnellerie.prototype.handleWindowResize = function(height) {
                windowHeight = height;
                
                if(Aneox.Data.Config.runtime.phone){
                   
                    var tmHeight = $('#tonnellerie-thibaut-montgillard .section-content .content-bloc-1').outerHeight(true);
                    $('#tonnellerie-thibaut-montgillard').height(tmHeight);
                    
                    var nbHeight = $('#tonnellerie-nicolas-boillot .section-content .content-bloc-1').outerHeight(true);
                    $('#tonnellerie-nicolas-boillot').height(nbHeight);
                    
                    var adHeight = $('#tonnellerie-merranderie .section-content .content-bloc-1').outerHeight(true);
                    $('#tonnellerie-merranderie').height(adHeight);

                    var perHeight = $('#tonnellerie-perspective .section-content .content-bloc-1').outerHeight(true);
                    $('#tonnellerie-perspective').height(perHeight);
                    
                   }
            };

            
            Tonnellerie.prototype.handleScroll = function(event) {
                if (!Aneox.Data.Config.runtime.supportsScrollEvents) return;
                                
                var lastSection = sections[sections.length - 1];
                
                var introTop = $('#tonnellerie-intro').offset().top;
                if (introTop < .75 * windowHeight){
                  TweenMax.to('#tonnellerie-intro', 1, {opacity: 1, y: -20, ease: Circ.easeOut});  
                }
                
                sections.forEach(function(section){
                  var offsetTop = $(mastElement +'-'+ section).offset().top;
                  
                    if (offsetTop < .75 * windowHeight){
                    
                        self.animations.setActiveSection(section);  
                    
                        if(section == lastSection) {
                          TweenMax.to('.credit', 0.25, {opacity: 1});    
                        } else {
                            TweenMax.to('.credit', 0.25, {opacity: 0});
                        }
                    }

                    if (!hasPlayedThroughAnimation[section]){ 
                        
                      if (offsetTop < .5 * windowHeight) { // on rentre dans une section
                        if(section != "mast") { // si c'est une section après mast
                            
                         sID++;
                          self.animations.animSvgInt(sID, section, "tonnellerie");
                            
                          var parentSection = document.getElementById('tonnellerie-'+section);
                          var parentSectionBg = $('#tonnellerie-'+section).find('.section-bg');
                          self.timeline.to(parentSectionBg, 4, {opacity: 1, scale: 1, ease: Circ.easeOut}, "-=1").addLabel(section+'-bg');
  
                          var SectionTxt = $('#tonnellerie-'+section).find('.content-bloc-1-content');
                          self.timeline.to(SectionTxt, 0.9, {opacity: 1, y: -20, ease: Circ.easeOut}, section+'-bg'+"-=3").addLabel(section+'-text');
 
                        }
                        hasPlayedThroughAnimation[section] = true;
                      }
                    }
                });
                
                
            };

            Tonnellerie.prototype.mouseEnter = function(area) {
                if (Aneox.Data.Config.runtime.mobile) return;
            };

            Tonnellerie.prototype.mouseLeave = function(area) {
                if (Aneox.Data.Config.runtime.mobile) return;
            };
            
            return Tonnellerie;
        })();

        Controllers.Tonnellerie = Tonnellerie;
    })(Aneox.Controllers || (Aneox.Controllers = {}));
})(Aneox || (Aneox = {}));