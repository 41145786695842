var Aneox;
(function(Aneox) {
    (function(Controllers) {
        'use strict';

        var Actualites = (function() {

            var self;
            var windowHeight;
            var hasPlayedThroughAnimation = {};
            var mastElement;
            var sections;

            function Actualites(animations, locale, router, drupaldata) {

                self = this;
                self.timeline = animations.timeline;
                self.router = router;
                self.locale = locale;
                self.animations = animations;
                self.drupalData = drupaldata;
                
                mastElement = "#actualites";
                sections = ['mast','articles'];

            }

            Actualites.prototype.enter = function() {
                
                //hasPlayedThroughAnimation = {};
                var menuLeft = $('#nav-menu').position().left;
                if(menuLeft < 0) {
                  self.timeline.to('#nav-menu', 1, {left: 0, opacity: 1});
                } // vérif position du menu avant toute apparition
 
                if($('#accordion').accordion( "instance" )) $('#accordion').accordion( "destroy" );
                setList();
                self.timeline.to('#languages-nav', 0.25, {opacity: 1});
                self.timeline.set('.credit', {opacity: 0, display: 'none'});
                self.timeline.set(mastElement, { display: 'block', opacity: 0 });
                self.timeline.call(self.handleWindowResize.bind(self, $(window).height()));
                self.timeline.call(function() { 
                    $('#right-content').scrollTop(0);
                    self.animations.setSvgHome('home-svg-7-mask');
                });
                self.timeline.to(mastElement, .5, { opacity: 1, display: 'block' });
                self.timeline.call(function(){
                    TweenMax.to('.background-mast', 3, {opacity: 1, scale: 1, ease: Circ.easeOut});
                    self.animations.animSvgHome('home-svg-7-mask','actualites');
                });
                
                
                if (!Aneox.Data.Config.runtime.supportsScrollEvents) {
                    $('.accordion-header').each(function() {
                        TweenMax.set($(this), {opacity: 1}); 
                    });
                }
             };
 
             Actualites.prototype.leave = function(callback) {
                 self.timeline.to(mastElement, .5, { display: 'none', opacity: 0 });
                 self.timeline.to(".credit", .5,{opacity: 0, display: 'none'}, "-=.5");
                 self.timeline.set('.background-mast', {opacity: 0, scale: 1.05});
                 self.timeline.set(mastElement+'-title-1, '+mastElement+'-title-2', {opacity:0})
                 if (callback) self.timeline.call(callback);
             };

            Actualites.prototype.handleWindowResize = function(height) {
                windowHeight = height;
            };

            Actualites.prototype.handleScroll = function(event) {
                if (!Aneox.Data.Config.runtime.supportsScrollEvents) return;
                
                var lastSection = sections[sections.length - 1];
                $('.accordion-header').each(function() {
                  var introTop = $(this).offset().top;
                  if (introTop < .75 * windowHeight){
                    TweenMax.to($(this), 0.5, {opacity: 1, ease: Circ.easeOut}); 
                  }
                });
                
                sections.forEach(function(section){
                    
                    var offsetTop = $(mastElement +'-articles').offset().top;
                    
                    if (offsetTop < .75 * windowHeight){
                          TweenMax.to('.credit', 0.25, {display: 'flex', opacity: 1});    
                    }else{
                        TweenMax.to('.credit', 0.25, {display: 'none', opacity: 0});
                    }
                    
                    
                    
                    if (!hasPlayedThroughAnimation[section]){
                        var offsetTop = $(mastElement +'-'+ section).offset().top;
                        
                        if (offsetTop < .75 * windowHeight) {
                            
                            /*Animations code*/
                            
                            /*animations.timeline.to(mastElement + section, .5, { opacity: 1 });
                           
                            animations.timeline.call(function() {
                                $(mastElement + section).find('.col-img').addClass('revealed');
                            });
                            

                            animations.timeline.staggerTo($(mastElement + section).find('.fill, h3, h4, p, .button'),
                                .5, { opacity: 1 }, .2);*/

                            hasPlayedThroughAnimation[section] = true;
                        }
                    }
                });
            };

            Actualites.prototype.mouseEnter = function(area) {
                if (Aneox.Data.Config.runtime.mobile) return;
            };

            Actualites.prototype.mouseLeave = function(area) {
                if (Aneox.Data.Config.runtime.mobile) return;
            };
            
            Actualites.prototype.closeActu = function(){
                
            }
            
            function setList(){
                
                var dataActu  = _.filter(self.drupalData.dataActu, {langue: self.locale.getLocale()});
                var article = '';
                var count = 0;
                
                dataActu.forEach(function(actu){
                    
                    var firstImage = '';
                    var otherImages = '';
                    var fichier = '';
                    var i=0;
                    
                    
                    
                    actu.images.forEach(function(image){
                        if(i == 0){
                           firstImage = '<a href="'+image+'" data-lightbox="actu'+count+'"><img data-src="'+image+'"  class="lazyload" /></a>';
                        }else{
                            otherImages +='<a href="'+image+'" class="article-thumb lazyload" data-lightbox="actu'+count+'" data-bg="'+image+'"></a>';
                            //otherImages +='<a href="'+image+'" data-lightbox="actu'+count+'" ><img class="lazyload" data-src="'+image+'" /></a>';
                        }
                        i++;
                    });
                    
                    
                    var closeText;
                    var lireSuiteText;
                    var telechargerText;
                    
                    if(self.locale.getLocale() == "fr"){
                        closeText = "Fermer";
                        lireSuiteText = "Lire la suite";
                        telechargerText = "Télécharger le fichier";
                    }else{
                        closeText = self.locale.getTranslation("Fermer", self.locale.getLocale());
                        lireSuiteText = self.locale.getTranslation("Lire la suite", self.locale.getLocale());
                        telechargerText = self.locale.getTranslation("Télécharger le fichier", self.locale.getLocale());
                    }
                    
                    
                    
                    if(!_.isEmpty(actu.fichier)) fichier = '<a href="'+actu.fichier+'" target="_blank" class="news-article-download" translate>'+telechargerText+'</a>';
                    else fichier = '';
                    
                    article +='<div class="accordion-header">';
                    article +='     <div class="col news-article-thumbnail">';
                    article += firstImage;
                    article +='     </div>';
                    article +='     <div class="col news-article-text">';
                    article +='         <button class="accordion-close" translate>'+closeText+'</button>'+actu.date+'<h2>'+actu.titre+'</h2><p>'+actu.resumer+'</p><button class="accordion-extend" translate>'+lireSuiteText+'</button>';
                    article +='     </div>';
                    article +='     <br class="clear" />';
                    article +='</div>';
                    article +='<div class="accordion-content">';
                    article +='     <div class="col news-article-thumbnail">';
                    article += otherImages;
                    article +='         <br class="clear" />';
                    article +=fichier;
                    article +='     </div>';
                    article +='     <div class="col news-article-text">';
                    article +='         <p>'+actu.texte+'</p>';
                    article +='     </div>';
                    article +='     <br class="clear" />';
                    article +='</div>';
                    
                    count++;
                });
                
                $('#accordion').html(article).promise().done(function(){
                    $('#accordion').accordion(
                    {
                        header: ".accordion-header",
                        collapsible: true,
                        heightStyle: "content",
                        active: false
                    });
                    
                    $('#accordion').on("accordionbeforeactivate", function(event, ui){

                        if(!_.isEmpty(ui.oldHeader[0])){
                            TweenMax.to('#'+ui.oldHeader[0].id+' .accordion-extend', 0.25, {display: 'block', opacity:1});                        
                            TweenMax.to('#'+ui.oldHeader[0].id+' .accordion-close', 0.25, {display: 'none', opacity:0});
                        }
                        
                        if(!_.isEmpty(ui.newHeader[0])){
                            TweenMax.to('#'+ui.newHeader[0].id+' .accordion-extend', 0.25, {display: 'none', opacity:0});                        
                            TweenMax.to('#'+ui.newHeader[0].id+' .accordion-close', 0.25, {display: 'block', opacity:1});                            
                        }
                        
                    });
                    
                    lightbox.option({
                      'resizeDuration': 200,
                      'wrapAround': true,
                      'disableScrolling': true,
                      'alwaysShowNavOnTouchDevices': true,
                        'alwaysShowNav': true
                    })
                    
                });
                
            }
            
            

            return Actualites;
        })();

        Controllers.Actualites = Actualites;
    })(Aneox.Controllers || (Aneox.Controllers = {}));
})(Aneox || (Aneox = {}));