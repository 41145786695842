var Aneox;
(function(Aneox) {
    'use strict';

    // Lazysizes - add simple support for background images
    document.addEventListener('lazybeforeunveil', function(e) {
        var bg = e.target.getAttribute('data-bg');
        if (bg) {
            e.target.style.backgroundImage = 'url(' + bg + ')';
        }
        
        var ajax = $(e.target).data('ajax');
        if(ajax){
            $(e.target).load(ajax);
        }
        
        
    });
    

    window.rootPath = _.trim(_.get(window, 'rootPath', ''));

    // Main bootlejs init
    var bottle = new Bottle();

    bottle.service('Library.Fonts', Aneox.Library.Fonts);
    bottle.service('Library.Locale', Aneox.Library.Locale);
    bottle.service('Library.Router', Aneox.Library.Router);
    bottle.service('Library.Analytics', Aneox.Library.Analytics, 'Library.Router');

    bottle.service('Animations', Aneox.Animations);
    bottle.service('Slideshow', Aneox.Slideshow, 'Animations');
    bottle.service('DrupalData', Aneox.DrupalData, 'Library.Locale', 'Library.Router');
    bottle.service('NavMenu', Aneox.NavMenu, 'Animations', 'Library.Locale', 'Library.Router');

    bottle.service('Controllers.Home', Aneox.Controllers.Home, 'Animations', 'Library.Locale', 'Library.Router');
    bottle.service('Controllers.Actualites', Aneox.Controllers.Actualites, 'Animations', 'Library.Locale', 'Library.Router', 'DrupalData');
    bottle.service('Controllers.Distributeurs', Aneox.Controllers.Distributeurs, 'Animations', 'Library.Locale', 'Library.Router', 'DrupalData');
    bottle.service('Controllers.Gamme', Aneox.Controllers.Gamme, 'Animations', 'Library.Locale', 'Library.Router');
    bottle.service('Controllers.Matieres', Aneox.Controllers.Matieres, 'Animations', 'Library.Locale', 'Library.Router');
    bottle.service('Controllers.Savoirfaire', Aneox.Controllers.Savoirfaire, 'Animations', 'Library.Locale', 'Library.Router');
    bottle.service('Controllers.Tonnellerie', Aneox.Controllers.Tonnellerie, 'Animations', 'Library.Locale', 'Library.Router');
    bottle.service('Controllers.Contact', Aneox.Controllers.Contact, 'Animations', 'Library.Locale', 'Library.Router');
    bottle.service('Controllers.Legal', Aneox.Controllers.Legal, 'Animations', 'Library.Locale', 'Library.Router');

    bottle.service('App', Aneox.App,
        'Library.Fonts', 'Library.Locale', 'Library.Router', 'Library.Analytics', 'DrupalData',
        'Animations', 'Slideshow', 'NavMenu',
        'Controllers.Home',
        'Controllers.Actualites',
        'Controllers.Distributeurs',
        'Controllers.Matieres',
        'Controllers.Gamme',
        'Controllers.Savoirfaire',
        'Controllers.Tonnellerie',
        'Controllers.Contact',
        'Controllers.Legal');


    window.app = bottle.container.App;

})(Aneox || (Aneox = {}));