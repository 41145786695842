var Aneox;
(function(Aneox) {
    (function(Controllers) {
        'use strict';

        var Home = (function() {

            var self;
            var windowHeight;
            var hasPlayedThroughAnimation = {};
            var mastElement;
            var sections;

            function Home(animations) {

                self = this;
                self.timeline = animations.timeline;
                self.animations = animations;
                mastElement = "#home";
            }
       
            Home.prototype.enter = function() {
                self.timeline.set(mastElement, { display: 'block', opacity: 0 });
                //self.timeline.set('.align-bottom',{opacity: 0});
                
                self.timeline.call(self.handleWindowResize.bind(self, $(window).height()));
                self.timeline.call(function() { 
                    $('#right-content').scrollTop(0);
                    //self.timeline.to('#nav-menu', 1, {left: 0, opacity: 1});
                    //self.timeline.to('#languages-nav', 0.25, {opacity: 1});
                    self.animations.setSvgHome('home-svg-1-mask');
                });
                self.timeline.to(mastElement, 1, { opacity: 1, display: 'block' });
                self.timeline.to('#nav-menu', 1, {left: 0, opacity: 1});
                //self.timeline.to('.background-mast', 3, {opacity: 1, scale: 1, ease: Circ.easeOut}, '-=1');
                //self.timeline.to('#languages-nav', 0.25, {opacity: 1}, '-=1');
                
                
                //self.timeline.to('.background-mast', 3, {opacity: 1, scale: 1, ease: Circ.easeOut}, '-=1');
                //self.timeline.to('#languages-nav', 0.25, {opacity: 1}, '-=1');
                
                
                self.timeline.call(function() {
                  //TweenMax.to('.background-mast', 3, {opacity: 1, scale: 1, ease: Circ.easeOut});
                  self.animations.animSvgHome('home-svg-1-mask','home'); 
                  
                });
                
                self.timeline.to('.background-mast', 3, {opacity: 1, scale: 1, ease: Circ.easeOut}, '-=1');
                self.timeline.to('#languages-nav', 0.25, {opacity: 1}, '-=1');
                
                self.timeline.to('.align-bottom', 1.5, {opacity: 1, y: -20}, "-=1");
                self.timeline.to('.credit', 0.25, {opacity: 1});
                 self.timeline.call(function(){
                    self.animations.setEndSvgHome('home-svg-1-mask');
                });
                
                //self.timeline.to('.align-bottom', 1.5, {opacity: 1});
                //self.timeline.to('.credit', 0.25, {opacity: 1}, '-=1');
                
                
                if (Aneox.Data.Config.runtime.supportsScrollEvents) {

                }
             };
 
             Home.prototype.leave = function(callback) {
                 self.timeline.to(mastElement, .5, { display: 'none', opacity: 0 });
                 self.timeline.set('.background-mast', {opacity: 0, scale: 1.05});
                 self.timeline.set(mastElement+'-title-1, '+mastElement+'-title-2', {opacity:0});
                 self.timeline.set('.align-bottom',{opacity: 0, y: 0});
                 if (callback) self.timeline.call(callback);
             };

            Home.prototype.handleWindowResize = function(height) {
                windowHeight = height;
            };

            Home.prototype.handleScroll = function(event) {
                
            };

            Home.prototype.mouseEnter = function(area) {
                if (Aneox.Data.Config.runtime.mobile) return;
            };

            Home.prototype.mouseLeave = function(area) {
                if (Aneox.Data.Config.runtime.mobile) return;
            };
            

            return Home;
        })();

        Controllers.Home = Home;
    })(Aneox.Controllers || (Aneox.Controllers = {}));
})(Aneox || (Aneox = {}));