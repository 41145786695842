var Aneox;
(function (Aneox) {
    'use strict';

    var hasPlayedThroughAnimation = {};

    var Animations = (function () {

        var self;
        
        function Animations() {
            this.timeline = new TimelineMax();
            
            self = this;
            
            $('.follow, .page-title-content h2').on('click', followScroll);
            
        }
        
        Animations.prototype.setPagerNav = function(mastElement, sections){
                var counter = 0;
                var elements = '';
                sections.forEach(function(section){
                    if(counter == 0){
                        elements += '<li onclick="app.animations.goToSection(0, this)" id="pager-'+section+'" class="active">&bull;</li>';
                    }else{
                        var offsetTop = $(mastElement+'-'+section).offset().top;
                    elements += '<li id="pager-'+section+'" onclick="app.animations.goToSection(\''+offsetTop+'\',this)">'+counter+'</li>';
                    }
                    counter++;
                });
                
                $("#pager-nav").html(elements);
        }
        
        Animations.prototype.goToSection = function(offsetTop,section){
           
            $("#pager-nav li").removeClass("active");
            $(section).addClass("active");
            
            animatedScrollTo(
                document.getElementById('right-content'),
                offsetTop,
                500
            );
            
        }
        
        Animations.prototype.setActiveSection = function(section){
            $("#pager-nav li").removeClass("active");
            $('#pager-'+section).addClass("active");
        }
        
        Animations.prototype.activeLegal = function(){
          $('.nav-side-item.credit').addClass('show');
        }
        
        Animations.prototype.unactiveLegal = function(){
          $('.nav-side-item.credit').removeClass('show');
        }
        
        Animations.prototype.animSvgHome = function(maskElement, sectionName){
            
            var t1 = new TimelineMax();
            
            var tab = [];
            for(var i=1; i<8; i++){

                tab.push('#'+maskElement+'-'+i+'');
            }
            
            var titleSec1 = '#'+sectionName+'-title-1';
            var titleSec2 = '#'+sectionName+'-title-2';
            var timer = "-=0";
            
            if(sectionName === "home"){
                timer ="-=0";
            }
            
            
            //self.timeline.to('#'+maskElement+'-'+i+'', 1, {ease: Circ.easeOut, strokeOpacity: 1, strokeDashoffset: 0}, label, time);
            t1.staggerTo(tab, 2, {ease: Circ.easeOut, strokeOpacity: 1, strokeDashoffset: 0},0.15, timer, function(){
                tab.forEach(function(element){
                    $(element).css({'stroke-dashoffset':0});
                });
            });
            t1.to(titleSec1, 0.9, {ease: Circ.easeOut, opacity: 1}, "-=2.3");
            t1.to(titleSec2, 0.9, {ease: Circ.easeOut, opacity: 1}, "-=2");
            t1.to('.follow', 0.9, {ease: Circ.easeOut, opacity: 1}, "-=1.3");
        }
        
        
        Animations.prototype.setSvgHome = function(maskElement){
            
            var stroleLength = [921.11, 22.10, 43.70, 77.10, 76.40, 106.90, 29];
            
            for(var i=1; i<8; i++){
                
                $('#'+maskElement+'-'+i+'').css({
                    'stroke-opacity': 0,
                    'stroke-dashoffset': stroleLength[i-1],
                    'stroke-dasharray': stroleLength[i-1]
                });
            }
        }
        
        Animations.prototype.setEndSvgHome = function(maskElement){
            
            
            for(var i=1; i<8; i++){
                
                $('#'+maskElement+'-'+i+'').css({
                    'stroke-opacity': 1,
                    'stroke-dashoffset': 0,
                    'stroke-dasharray': 0
                });
            }
        }
        
        Animations.prototype.animSvgInt = function(sID, section, sectMast){
            self.timeline.to('#deco-'+sectMast+'-'+sID+'-mask-1', .4, {strokeOpacity: 1, strokeDashoffset: 0, ease: Circ.easeOut});
            self.timeline.to('#deco-'+sectMast+'-'+sID+'-mask-2', .4, {strokeOpacity: 1, strokeDashoffset: 0, ease: Circ.easeOut}); 
            self.timeline.to('#deco-'+sectMast+'-'+sID+'-mask-3', .4, {strokeOpacity: 1, strokeDashoffset: 0, ease: Circ.easeOut});
        }
        
        Animations.prototype.noScrollSvgInt = function(sID, section, sectMast){
            self.timeline.set('#deco-'+sectMast+'-'+sID+'-mask-1',{strokeOpacity: 1, strokeDashoffset: 0});
            self.timeline.set('#deco-'+sectMast+'-'+sID+'-mask-2',{strokeOpacity: 1, strokeDashoffset: 0}); 
            self.timeline.set('#deco-'+sectMast+'-'+sID+'-mask-3',{strokeOpacity: 1, strokeDashoffset: 0});
        }
        
        
        function followScroll(e){
            
            var offsetTop = $(window).height() /2; 
            
            animatedScrollTo(
                document.getElementById('right-content'),
                offsetTop,
                500
            );
        }

        // Animations.prototype.animateTitle = function(titleElement) {
        //     if (titleElement.children.length) {
        //         TweenMax.staggerTo(titleElement.children, .5,
        //             { opacity: 1, paddingTop: '.1em' }, .2);
        //     } else {
        //         TweenMax.to(titleElement, .5,
        //             { opacity: 1, paddingTop: 0 });
        //     }
        // };

        // Animations.prototype.animateMenu = function() {
        //     if (!hasPlayedThroughAnimation.nav) {
        //         this.timeline.set('#nav-menu', {
        //             opacity: 1
        //         });
        //         this.timeline.from('#nav-menu', 1, {
        //             height: 0,
        //             paddingTop: 0,
        //             paddingBottom: 0,
        //             overflow: 'hidden',
        //             delay: 2,
        //             onComplete: function() {
        //                 $('#nav-menu').attr('style', 'opacity: 1');
        //             }
        //          });
        //         hasPlayedThroughAnimation.nav = true;
        //     }

        //     if (!hasPlayedThroughAnimation.footer) {
        //         this.timeline.to('.footer-wrapper', .5, {
        //             display: 'block',
        //             opacity: 1
        //         });
        //         hasPlayedThroughAnimation.footer = true;
        //     }
        // };

        return Animations;
    })();

    Aneox.Animations = Animations;
})(Aneox || (Aneox = {}));