var Aneox;
(function(Aneox) {
    (function(Controllers) {
        'use strict';

        var Contact = (function() {

            var self;
            var windowHeight;
            var hasPlayedThroughAnimation = {};
            var mastElement;
            var sections;

            function Contact(animations) {

                self = this;
                self.timeline = animations.timeline;
                self.animations = animations;
                mastElement = "#contact";
                sections = ['mast','contact'];
            }

            Contact.prototype.enter = function() {
                
                //hasPlayedThroughAnimation = {};
                var menuLeft = $('#nav-menu').position().left;
                if(menuLeft < 0) {
                  self.timeline.to('#nav-menu', 1, {left: 0, opacity: 1});
                } // vérif position du menu avant toute apparition
                self.timeline.to('#languages-nav', 0.25, {opacity: 1});
                self.timeline.set(mastElement, { display: 'block', opacity: 0 });
                self.timeline.call(self.handleWindowResize.bind(self, $(window).height()));
                self.timeline.call(function() { 
                    $('#right-content').scrollTop(0);
                    self.animations.setSvgHome('home-svg-9-mask');
                });
                self.timeline.to(mastElement, .5, { opacity: 1, display: 'block' });
                self.timeline.to('.credit', 0.25, {opacity: 1});
                self.timeline.call(function(){
                    TweenMax.to('.background-mast', 3, {opacity: 1, scale: 1, ease: Circ.easeOut});
                    self.animations.animSvgHome('home-svg-9-mask','contact');
                     
                });
                self.timeline.to('#contact-content', 1, {opacity: 1, ease: Circ.easeOut}, "+=1.5");
 
                if (Aneox.Data.Config.runtime.supportsScrollEvents) {

                }
             };
 
             Contact.prototype.leave = function(callback) {
                 self.timeline.to(mastElement, .5, { display: 'none', opacity: 0 });
                 self.timeline.set('.background-mast', {opacity: 0, scale: 1.05});
                 self.timeline.set(mastElement+'-title-1, '+mastElement+'-title-2', {opacity:0})
                 if (callback) self.timeline.call(callback);
             };

            Contact.prototype.handleWindowResize = function() {
                // windowHeight = $(window).height();
            };

            Contact.prototype.handleScroll = function(event) {
                if (!Aneox.Data.Config.runtime.supportsScrollEvents) return;
               
            };

            Contact.prototype.mouseEnter = function(area) {
                if (Aneox.Data.Config.runtime.mobile) return;
            };

            Contact.prototype.mouseLeave = function(area) {
                if (Aneox.Data.Config.runtime.mobile) return;
            };

            return Contact;
        })();

        Controllers.Contact = Contact;
    })(Aneox.Controllers || (Aneox.Controllers = {}));
})(Aneox || (Aneox = {}));