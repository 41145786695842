var Aneox;
(function (Aneox) {
    'use strict';

    var Slideshow = (function () {

        var images;
        var currentIndex;

        var animations;

        function Slideshow(anims) {
            animations = anims;
        }

        Slideshow.prototype.open = function(id) {
            var base = $('base').attr('href');

            images = _.find(Aneox.Data.Data.slideshows, { id: id }).img;
            currentIndex = 0;
            $('#slideshow-slides').html(images.map(function(image, index) {
                return '<div class="slideshow-img-wrapper' + (index === 0 ? ' active' : '') + '">' +
                    '<div class="slideshow-img background-cover lazyload" data-bg="' + base + image + '"></div>' +
                '</div>';
            }).join(''));

            animations.timeline.to('#slideshow', .5, { opacity: 1, display: 'block' });
        };

        Slideshow.prototype.next = function() {
            currentIndex++;
            if (currentIndex >= images.length) {
                currentIndex = 0;
            }
            $('.slideshow-img-wrapper.active').removeClass('active');
            $('.slideshow-img-wrapper').eq(currentIndex).addClass('active');
        };

        Slideshow.prototype.previous = function() {
            currentIndex--;
            if (currentIndex < 0) {
                currentIndex = images.length - 1;
            }
            $('.slideshow-img-wrapper.active').removeClass('active');
            $('.slideshow-img-wrapper').eq(currentIndex).addClass('active');
        };

        Slideshow.prototype.close = function() {
            animations.timeline.to('#slideshow', .5, { opacity: 0, display: 'none' });
        };

        return Slideshow;
    })();

    Aneox.Slideshow = Slideshow;
})(Aneox || (Aneox = {}));