var Aneox;
(function(Aneox) {
    (function(Controllers) {
        'use strict';

        var Legal = (function() {

            var self;
            var windowHeight;
            var hasPlayedThroughAnimation = {};
            var mastElement;
            var sections;

            function Legal(animations) {

                self = this;
                self.timeline = animations.timeline;
                self.animations = animations;
                mastElement = "#legal";

            }

            Legal.prototype.enter = function() {
                
                //hasPlayedThroughAnimation = {};
                var menuLeft = $('#nav-menu').position().left;
                if(menuLeft < 0) {
                  self.timeline.to('#nav-menu', 1, {left: 0, opacity: 1});
                } // vérif position du menu avant toute apparition
                self.timeline.to('#languages-nav', 0.25, {opacity: 1});
                self.timeline.set(mastElement, { display: 'block', opacity: 0 });
                self.timeline.call(self.handleWindowResize.bind(self, $(window).height()));
                self.timeline.call(function() { 
                    $('#right-content').scrollTop(0);
                    self.animations.setSvgHome('home-svg-10-mask');
                });
                self.timeline.to(mastElement, .5, { opacity: 1, display: 'block' });
                self.timeline.call(function(){
                    TweenMax.to('.background-mast', 3, {opacity: 1, scale: 1, ease: Circ.easeOut});
                    self.animations.animSvgHome('home-svg-10-mask','legal');
                });
                
                if (Aneox.Data.Config.runtime.supportsScrollEvents) {

                }
             };
 
             Legal.prototype.leave = function(callback) {
                 self.timeline.to(mastElement, .5, { display: 'none', opacity: 0 });
                 self.timeline.to(".credit", .5,{opacity: 0}, "-=.5");
                 self.timeline.set('.background-mast', {opacity: 0, scale: 1.05});
                 self.timeline.set(mastElement+'-title-1, '+mastElement+'-title-2', {opacity:0})
                 if (callback) self.timeline.call(callback);
                
             };

            Legal.prototype.handleWindowResize = function(height) {
                windowHeight = height;
            };

            Legal.prototype.handleScroll = function(event) {
                
            };

            Legal.prototype.mouseEnter = function(area) {
                if (Aneox.Data.Config.runtime.mobile) return;
            };

            Legal.prototype.mouseLeave = function(area) {
                if (Aneox.Data.Config.runtime.mobile) return;
            };

            return Legal;
        })();

        Controllers.Legal = Legal;
    })(Aneox.Controllers || (Aneox.Controllers = {}));
})(Aneox || (Aneox = {}));