var Aneox;
(function (Aneox) {
    'use strict';

    var DrupalData = (function () {

        var self;
        var basePath;
        var xmlToJson;
        
        function DrupalData(locale, router) {
            this.router = router;
            this.locale = locale;
            
            self = this;
            
            basePath = $('head base').attr('href');

        }
        
        /*initial data loading*/
        DrupalData.prototype.init = function(){
            $.get(basePath+'api/views/continents_pays.json?display_id=services_1', function(data){
                self.dataContinentsPays = data.map(function(a){

                    var parentTid = (_.isEmpty(a.parent_tid))? 0: _.parseInt(a.parent_tid);

                    return{
                        "name": a.name,
                        "tid": _.parseInt(a.tid),
                        "parent_tid": parentTid,
                        "localeen": a.localeen,
                        "localees": a.localees,
                        "localeit": a.localeit
                    }
                });

                $.get(basePath+'api/views/liste_importateurs.json?display_id=services_1', function(data){
                    self.dataDistributors = data.map(function(a){
                        console.log(a.pays);
                        return{
                            "nid": a.nid,
                            "region": a.region,
                            "name": a.nom,
                            "statut": a.statut,
                            "tel": a.tel,
                            "email": a.email,
                            "site": a.site,
                            "countries": _.parseInt(a.pays)
                        }
                    });
                    
                    $.get(basePath+'api/liste-actualites.json?display_id=services_1', function(data){
                        self.dataActu = data;
                        
                        $(document).trigger('dataLoaded');
                    });
                });
            });
        };

        DrupalData.prototype.open = function(id) {
           
        };
        
        DrupalData.prototype.getNode = function(nodeId){
            
        };
        
        DrupalData.prototype.getView = function(viewId){
            
        };

        return DrupalData;
    })();

    Aneox.DrupalData = DrupalData;
})(Aneox || (Aneox = {}));