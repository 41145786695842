var Aneox;
(function(Aneox) {
    (function(Controllers) {
        'use strict';

        var Savoirfaire = (function() { 

            var self;
            var windowHeight;
            var hasPlayedThroughAnimation = {};
            var mastElement;
            var sections;
            var sID = 0;

            function Savoirfaire(animations) {

                self = this;
                self.timeline = animations.timeline;
                self.animations = animations;
                
                mastElement = "#savoirfaire";
                sections = ['mast','approche','expertise','vecu'];
                
            }

            Savoirfaire.prototype.enter = function() {
                
                //hasPlayedThroughAnimation = {};
                var menuLeft = $('#nav-menu').position().left;
                if(menuLeft < 0) {
                  self.timeline.to('#nav-menu', 1, {left: 0, opacity: 1});
                } // vérif position du menu avant toute apparition
                self.timeline.to('#languages-nav', 0.25, {opacity: 1});
                self.timeline.set('.credit', {opacity: 0, display: 'none'});
                self.timeline.set(mastElement, { display: 'block', opacity: 0 });
                self.timeline.call(self.handleWindowResize.bind(self, $(window).height()));
                self.timeline.call(function() { 
                    $('#right-content').scrollTop(0);
                    self.animations.setSvgHome('home-svg-3-mask');
                });
                self.timeline.to(mastElement, .5, { opacity: 1, display: 'block' });
                self.timeline.call(function(){
                    TweenMax.to('.background-mast', 3, {opacity: 1, scale: 1, ease: Circ.easeOut});
                    self.animations.setPagerNav(mastElement, sections);
                    self.timeline.to("#pager-nav", .5, {display: 'block', opacity: 1});
                    self.animations.animSvgHome('home-svg-3-mask','savoirfaire'); 
                });
                
                if (!Aneox.Data.Config.runtime.supportsScrollEvents) {
                    TweenMax.set('#savoirfaire-intro', {opacity: 1, y: -20});
                    sID = 0;
                    sections.forEach(function(section){
                        sID++;
                        self.animations.noScrollSvgInt(sID, section, "savoirfaire");
                        
                        var parentSection = document.getElementById('savoirfaire-'+section);
                          var parentSectionBg = $('#savoirfaire-'+section).find('.section-bg');
                          self.timeline.set(parentSectionBg, {opacity: 1, scale: 1});
                          
                          var SectionTxt = $('#savoirfaire-'+section).find('.content-bloc-1-content');
                          self.timeline.set(SectionTxt, {opacity: 1, y: -20});
                    });
                }
             };
 
             Savoirfaire.prototype.leave = function(callback) {
                 self.timeline.to(mastElement, .5, { display: 'none', opacity: 0 });
                 self.timeline.to("#pager-nav", .5,{display: 'none', opacity: 0}, "-=.5"); 
                 self.timeline.to(".credit", .5,{opacity: 0, display: 'none'}, "-=.5");
                 self.timeline.set('.background-mast', {opacity: 0, scale: 1.05});
                 self.timeline.set(mastElement+'-title-1, '+mastElement+'-title-2', {opacity:0})
                 if (callback) self.timeline.call(callback);
                
             };

            Savoirfaire.prototype.handleWindowResize = function(height) {
                windowHeight = height;
                
                if(Aneox.Data.Config.runtime.phone){
                   
                    var tmHeight = $('#savoirfaire-approche .section-content .content-bloc-1').outerHeight(true);
                    $('#savoirfaire-approche').height(tmHeight);
                    
                    var nbHeight = $('#savoirfaire-expertise .section-content .content-bloc-1').outerHeight(true);
                    $('#savoirfaire-expertise').height(nbHeight);
                    
                    var adHeight = $('#tonnellerie-vecu .section-content .content-bloc-1').outerHeight(true);
                    $('#savoirfaire-vecu').height(adHeight);
                    
                   }
            };

            Savoirfaire.prototype.handleScroll = function(event) {
                if (!Aneox.Data.Config.runtime.supportsScrollEvents) return;
                var lastSection = sections[sections.length - 1];
               
                var introTop = $('#savoirfaire-intro').offset().top;
                if (introTop < .75 * windowHeight){
                  TweenMax.to('#savoirfaire-intro', 0.5, {opacity: 1, y: -20, ease: Circ.easeOut}); 
                }
            
                sections.forEach(function(section){
                    var offsetTop = $(mastElement +'-'+ section).offset().top;
                    if (offsetTop < .75 * windowHeight){
                         self.animations.setActiveSection(section);
                         if(section == lastSection) {
                          TweenMax.to('.credit', 0.25, {opacity: 1, display: 'flex'});    
                        } else {
                            TweenMax.to('.credit', 0.25, {opacity: 0, display: 'none'});
                        }
                    }

                    if (!hasPlayedThroughAnimation[section]){ 
                      if (offsetTop < .5 * windowHeight) { // on rentre dans une section
                        if(section != "mast") { // si c'est une section après mast
                            
                          sID++;
                          self.animations.animSvgInt(sID, section, "savoirfaire");
                            
                          var parentSection = document.getElementById('savoirfaire-'+section);
                          var parentSectionBg = $('#savoirfaire-'+section).find('.section-bg');
                          self.timeline.to(parentSectionBg, 2, {opacity: 1, scale: 1, ease: Circ.easeOut}).addLabel(section+'-bg');
                          // image de fond
                            
                          var SectionTxt = $('#savoirfaire-'+section).find('.content-bloc-1-content');
                          self.timeline.to(SectionTxt, 0.9, {opacity: 1, y: -20, ease: Circ.easeOut}, section+'-bg'+"-=1").addLabel(section+'-text'); 
                          // texte
                        }
                        hasPlayedThroughAnimation[section] = true;
                      }
                    }
                });
            };

            Savoirfaire.prototype.mouseEnter = function(area) {
                if (Aneox.Data.Config.runtime.mobile) return;
            };

            Savoirfaire.prototype.mouseLeave = function(area) {
                if (Aneox.Data.Config.runtime.mobile) return;
            };

            return Savoirfaire;
        })();

        Controllers.Savoirfaire = Savoirfaire;
    })(Aneox.Controllers || (Aneox.Controllers = {}));
})(Aneox || (Aneox = {}));